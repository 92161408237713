@import '@/styles/viewport';
@keyframes typing {
  0% {
    opacity: 0;
    width: 0;
  }
  10% {
    opacity: 0;
    width: 0;
  }
  20% {
    opacity: 0.5;
    width: calc((228 * 0.4));
  }
  30% {
    opacity: 1;
    width: 228px;
  }
  60% {
    opacity: 1;
    width: 228px;
  }
  100% {
    opacity: 1;
    width: 228px;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0.2;
    width: 0;
  }
  5% {
    opacity: 0.4;
    width: calc((228 * 0.6));
  }
  10% {
    opacity: 0.8;
    width: calc((228 * 0.8));
  }
  13% {
    opacity: 1;
    width: 228px;
  }
  20% {
    opacity: 1;
    width: 228px;
  }
  100% {
    opacity: 1;
    width: 228px;
  }
}
/* mobile端放大缩回闪烁效果*/
@keyframes scaleDraw {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.3);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
.academicAdvisorContainer {
  .pcContent {
    .btn {
      position: fixed;
      bottom: 90px;
      width: 48px;
      height: 48px;
      right: 20px;
      z-index: 999;
      border: 2px solid #fff;
      border-radius: 24px;
      .avator {
        border-radius: 24px;
      }
    }
    .qrcodeContainer {
      position: fixed;
      bottom: 90px;
      right: 78px;
      z-index: 999;
      width: 228px;
      height: auto;
      min-height: 243px;
      padding: 12px 16px;
      border-radius: 16px 16px 4px 16px;
      border: 2px solid rgba(255, 255, 255, 0.8);
      background: linear-gradient(
          90deg,
          rgba(255, 83, 83, 0.16) 14.29%,
          rgba(109, 56, 251, 0.16) 63.38%,
          rgba(0, 157, 255, 0.16) 99.65%
        ),
        #fff;
      box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.08);

      .title {
        margin: 0;
        padding: 0;
        color: #1b1f4d;
        font-size: 14px;
        font-weight: 600;
        line-height: 24px; /* 171.429% */
      }
      .addTypes {
        display: flex;
        padding: 12px;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        border-radius: 12px;
        background: #fff;
        height: auto;
        min-height: 159px;
        width: 204px;
        margin-top: 12px;
        .intro {
          color: #1b1f4d;
          font-size: 13px;
          font-weight: 400;
          line-height: 20px;
        }
        .qrcodeBox {
          display: flex;
          justify-content: space-around;
          width: 100%;
          margin-top: 18px;
          .qrItem {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            .code {
              width: 70px;
              height: 70px;
            }
            span {
              color: #8d8fa6;
              font-size: 14px;
              font-weight: 400;
              text-align: center;
              margin-top: 10px;
            }
          }
        }
      }
    }
    .dialog {
      position: fixed;
      bottom: 90px;
      right: 78px;
      z-index: 999;
      display: flex;
      width: 228px;
      height: 48px;
      padding: 12px 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      border-radius: 16px 16px 4px 16px;
      border: 2px solid rgba(255, 255, 255, 0.8);
      background: linear-gradient(
          90deg,
          rgba(255, 83, 83, 0.16) 14.29%,
          rgba(109, 56, 251, 0.16) 63.38%,
          rgba(0, 157, 255, 0.16) 99.65%
        ),
        #fff;
      box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.08);
      animation: fade-in 5s; /*动画名称*/
      animation-duration: 5s; /*动画持续时间*/
      -webkit-animation: fade-in 5s; /*针对webkit内核*/
      animation-iteration-count: infinite;
      .intro {
        color: #1b1f4d;
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
        height: 48px;
        width: 228px;
        white-space: nowrap;
        overflow: hidden;
        animation: typing 5s steps(20) infinite;
      }
    }
  }

  .mobileContent {
    .btn {
      position: fixed;
      bottom: vw(90);
      width: vw(48);
      height: vw(48);
      right: 20px;
      z-index: 999;
      border: 2px solid #fff;
      border-radius: 50%;
      animation-name: scaleDraw; /*关键帧名称*/
      animation-timing-function: ease-in-out; /*动画的速度曲线*/
      animation-iteration-count: infinite; /*动画播放的次数*/
      animation-duration: 5s;
      .avator {
        border-radius: vw(24);
      }
    }
    .qrcodeContainer {
      position: fixed;
      bottom: vw(90);
      right: vw(78);
      z-index: 999;
      width: vw(228);
      height: auto;
      min-height: vw(208);
      padding: 12px 16px;
      border-radius: 16px 16px 4px 16px;
      border: 2px solid rgba(255, 255, 255, 0.2);
      background: linear-gradient(
          90deg,
          rgba(255, 83, 83, 0.16) 14.29%,
          rgba(109, 56, 251, 0.16) 63.38%,
          rgba(0, 157, 255, 0.16) 99.65%
        ),
        #fff;
      box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.08);
      .title {
        margin: 0;
        padding: 0;
        color: #1b1f4d;
        font-size: vw(14);
        font-weight: bold;
      }
      .addTypes {
        display: flex;
        padding: vw(12);
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        border-radius: vw(12);
        background: #fff;
        width: vw(204);
        min-height: vw(124);
        height: auto;
        margin-top: vw(12);
        .intro {
          color: #1b1f4d;
          font-size: vw(12);
        }
        .qrcodeBox {
          display: flex;
          justify-content: space-around;
          align-items: center;
          width: 100%;
          margin-top: vw(12);
          .qrItem {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            .code {
              width: vw(40);
              height: vw(40);
            }
            .whatsappText {
              font-size: vw(12);
              font-weight: 400;
              text-align: center;
              margin-top: vw(12);
              color: #40c351;
              text-decoration: none;
            }
            .wechatText {
              color: #5a6ee0;
              font-size: vw(12);
              font-weight: 400;
              text-align: center;
              margin-top: vw(12);
            }
          }
          .split {
            height: vw(40);
            width: vw(1);
            background-color: #ebecfa;
          }
        }
      }
    }
  }
}
