.input-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  overflow: hidden;
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  border-radius: 16px;
  &-medium {
    height: 62px;
  }
  &-small {
    height: 52px;
  }
}
.input-wrapper-tip-border {
  border: 1px solid #ff5353 !important;
}

.input {
  border: none;
  padding: 0 0 0 20px;
  background-color: transparent;
  outline: none;
  font-size: 16px;
  flex: 1;
  text-align: left;
  box-sizing: border-box;
  border-radius: 16px;
  &::placeholder {
    color: #c2c2c2;
    text-align: left;
    font-weight: 400;
  }
}
