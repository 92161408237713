.container {
  display: flex;
  width: 100%;
  min-height: 100vh;
  flex-direction: column;
  overflow: hidden;
  // font-family: 'Roboto','SourceHanSansCN';
}

.header {
  flex: 0 0 auto;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1 0 auto;
}

.footer {
  flex: 0 0 auto;
}