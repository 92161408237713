.button {
  display: inline-flex;
  padding: 8px 14px;
  width: auto;
  min-width: 80px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  user-select: none;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 14px;
  font-weight: 400;
  position: relative;
  &:hover {
    &:active {
      box-shadow: none;
      transform: translateY(2px);
    }
  }
  &-disabled {
    cursor: not-allowed;
    opacity: 0.3;
    box-shadow: none !important;
  }
  &-red {
    background: #ff5353;
    color: #fff;
    border-radius: 25px;
    box-shadow: 0px 2px 0px #e04343;
    transition: all 0.1 ease-out;
    -webkit-appearance: none;
  }
  &-blue {
    background: #7bd7ff;
    box-shadow: inset 0px -4px 0px rgba(16, 183, 255, 0.6);
    color: #fff;
    border-radius: 25px;
  }
  &-yellow {
    background: #feb221;
    color: #fff;
  }
  &-white {
    background-color: #fff;
    color: #666;
    border: 1px solid #ccc;
  }
  &-loading {
    opacity: 0.5;
    cursor: not-allowed;
  }
  &-green {
    background: #3de38d;
    box-shadow: inset 0px -4px 0px rgba(32, 184, 27, 0.4);
    color: #fff;
    border-radius: 120px;
  }
  &-gray {
    background-color: #ddd;
    color: #fff;
    &-disable {
      opacity: 1;
    }
  }
}
.loading {
  margin-right: 10px;
  display: flex;
  align-items: center;
}
.btn {
  position: absolute;
  top: 4px;
  right: 8px;
}
