.loading {
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  &-node {
    position: absolute;
  }
  &-index {
    z-index: -1;
  }
}
.content {
  width: 260px;
}
