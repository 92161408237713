.popover-wrapper {
  position: relative;
  display: inline-block;
}

.popover-target {
  cursor: pointer;
}

.popover-content {
  position: absolute;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.10);
  background: #FFF;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.12);
  padding: 12px 8px;
  z-index: 999;
  visibility: hidden; 
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.popover-title {
  // ui暂无title样式 后续可补充修改
  font-weight: bold;
  margin-bottom: 8px;
}

.popover-body {
}


.popover-arrow {
  border-bottom: -40px;
  width: 13px;
  height: 6px;
  background: url("/imgs/pc/popoverArrow.png") center / contain no-repeat;
  position: absolute;
}

.show {
  visibility: visible;
  opacity: 1;
}

/* 上 样式 */
.popover-content.top {
  left: 50%;
  transform: translateX(-50%);
}

.popover-content.top .popover-arrow {
  left: 50%;
  transform: translateX(-50%);
  bottom: -6px;
}

/* 下 样式 */ 
// ui暂无title样式 后续可修改
.popover-content.bottom {
  left: 50%;
  transform: translateX(-50%);
}

.popover-content.bottom .popover-arrow {
  left: 50%;
  transform: translateY(-50%) rotate(180deg);
  top: -2px;
  margin-left: -5px;
}

/* 左 样式 */
// ui暂无title样式 后续可修改
.popover-content.left {
  transform: translateX(-110%);
  top: 0px;
}

.popover-content.left .popover-arrow {
  right: -9px;
  top: 9px;
  transform: rotate(268deg);
}


/* 右 样式 */
// ui暂无title样式 后续可修改
.popover-content.right {
  top:0;
  transform: translateX(46%);
}

.popover-content.right .popover-arrow {
  top: 9px;
  left: -9px;
  transform: rotate(90deg);
}

@keyframes scale-in-center {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
