.nodata-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  &.big {
    span {
     margin-top: 28px;
    }
  }
  img {
    width: 120px;
  }
  span {
    color: #8D8FA6;
    font-size: 12px;
    margin-top: 10px;
  }
}