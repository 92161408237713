.mask {
  &-container {
    position: fixed;
    z-index: 100;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    animation: slide 0.45s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
}

@keyframes slide {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

