.dropdown-wrapper {
  position: relative;
}
.options {
  position: absolute;
  top: 48px;
  padding: 10px;
  box-shadow: 0 0 4px #C4C4C4;
  border-radius: 16px;
  background-color: #fff;
  transform: scaleY(0);
  z-index: 100;
  transform-origin: top left;
  transition: transform .3s ease-out .1s;
  user-select: none;
  &::before {
    position: absolute;
    content: '';
    left: 50%;
    background-color: #fff;
    width: 11px;
    height: 11px;
    top: -5px;
    transform: translateX(-50%);
    box-shadow: -1px -1px 2px #e2e2e2;
    transform: rotate(45deg);
    border-top-left-radius: 4px;
  }
  &-show {
    transform: scaleY(1);
    transform-origin: top;
    transition: transform .15s cubic-bezier(.32,.94,.6,1) 0s;
  }
  &:hover {
    transform: scaleY(1);
    transform-origin: top;
    transition: transform .15s cubic-bezier(.32,.94,.6,1) 0s;
  }
}