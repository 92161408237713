.star-disabled {
  display: none;
}
.stars {
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  span {
    cursor: pointer;
    display: inline-flex;
  }
}
