.modal-wrapper {
  border: 14px solid #c0ecff;
  border-radius: 90px;
  width: 802px;
  height: auto;
  z-index: 99;
  box-sizing: content-box;
  position: relative;
}
.header-box {
  padding-top: 40px;
  position: absolute;
  left: 48%;
  transform: translateX(-48%);
  width: 76%;
  z-index: 100;
}
.box-line {
  position: absolute;
  width: 100%;
  height: 24px;
  border: 12px solid #c0ecff;
  border-top-left-radius: 90px;
  border-top-right-radius: 90px;
  border-bottom: none;
  left: -14px;
}
.box-title {
  position: absolute;
  left: 48%;
  transform: translateX(-48%);
  top: 0px;
  height: 60px;
  width: auto;
  min-width: 300px;
  background-color: red;
  background: #bdebff;
  border: 12px solid #96dfff;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  border-bottom: none;
  padding: 2px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  span {
    display: inline-block;
    margin: 0 20px;
    font-size: 22px;
    max-width: 310px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-shadow: rgb(150, 223, 255) 2px 0 0, rgb(150, 223, 255) 0 2px 0, rgb(150, 223, 255) -2px 0 0,
      rgb(150, 223, 255) 0 -2px 0;
  }
}
.close {
  position: absolute;
  right: -124px;
  width: 58px;
  top: 55px;
  cursor: pointer;
}
