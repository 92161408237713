.pre-icon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;
  // border: 1px solid #F0F0F0;
  box-sizing: border-box;
  // box-shadow: 0px 2.4px 4.8px rgba(210, 217, 222, 0.17);
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    transform: rotate(180deg);
  }
}
.pagination {
  display: flex;
  padding: 8px 0;
  
  :global(.rc-pagination-item) {
    margin: 0 8px;
  }
  
  :global(.rc-pagination-item-link) {
    padding: 1px 0;
    text-align: center;
  }
  
  :global(.rc-pagination-jump-next button) {
    border-width: 2px;
    border-style: outset;
    border-color: transparent;
    border-image: initial;
  }
  
  :global(.rc-pagination-jump-next button:after) {
    font-size: 12px;
  }
  
  li {
    display: inline-flex;
    align-items: center;
    width: 24px;
    height: 24px;
    min-width: 24px;
    margin: 0 6px !important;
  }
}
.next-icon {
  transform: rotate(180deg);
}
.page {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  
  box-sizing: border-box;
  
  color: #484C70;
  font-size: 12px;
  font-weight: 400;
  box-shadow: none !important;
  // margin-right: 12px;
  &-active {
    color: #5A6EE0;
    background: rgba(90, 110, 224, 0.08);
    // font-weight: 600;
    // margin-right: 4px;
    // border: 1px solid #F0F0F0;
    // box-shadow: 0px 2.4px 4.8px rgba(210, 217, 222, 0.17);
    border-radius: 50%;
  }
}

.rc-pagination-item  {
  margin-right: 0;
  div {
    box-shadow: none;
  }
}