@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .hide-scrollbar::-webkit-scrollbar {
     display: none;
  }

  .forbid-select-bg {
    -webkit-tap-highlight-color: transparent;
  }
}