$namespace: modal;
$border-radius: 16px;
$title-border-radius: 20px;
$white: #ffffff;
$grey-1: #111111;
$grey-2: #333333;
$grey-3: #555555;
$grey-extra-1: #f7f7f7;
$fw-mediun: 500;

.#{$namespace} {
  &-wrap {
    position: fixed;
    left: 0;
    right: 0;
    height: 100vh;
    top: 0;
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 100;
    overflow: auto;
  }
  &-mask {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-box {
    position: relative;
    margin: auto;
    border-radius: $border-radius;
    display: flex;
  }
  &-container {
    position: relative;
    background: $white;
    margin-top: 74px;
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.15);
    -webkit-tap-highlight-color: transparent;
  }

  &-title {
    background: $grey-extra-1;
    border-radius: $title-border-radius $title-border-radius 0 0;
    height: 60px;
    text-align: center;
    color: $grey-2;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-close {
    position: absolute;
    right: 10px;
    top: 12px;
    flex: 0 0 auto;
    width: 34px;
    height: 34px;
    justify-content: flex-end;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    cursor: pointer;
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &-has-title {
    &.#{$namespace}-container {
      border-radius: $title-border-radius $title-border-radius $border-radius $border-radius;
    }
    .#{$namespace}-body {
      padding-top: 40px;
    }
    .#{$namespace}-info-body {
      padding-top: 16px;
    }
  }

  &-body {
    padding: 50px 50px 40px;
    font-style: normal;
    font-weight: $fw-mediun;
    text-align: center;
    color: $grey-3;
    margin: 0 auto;
  }

  &-info-body {
    padding: 16px 16px 16px 16px;
  }

  &-info-container {
    padding-bottom: 0px;
  }

  &-icon {
    width: 130px;
    height: 130px;
    margin: 0 auto 24px;
    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }
  }

  &-footer {
    text-align: center;
  }

  &-footer-button {
    margin: 0 20px;
  }

  &-hide {
    display: none;
  }
  &-enter {
    display: flex;
  }

  &-exit {
    animation: #{$namespace}-out 0.3s ease-out forwards;
  }

  &-enter-active {
    display: flex;
    animation: #{$namespace}-in 0.6s ease-out forwards;
  }

  &-enter-done {
    display: flex;
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes #{$namespace}-in {
  from {
    opacity: 1;
    transform: scale(0.3);
  }
  30% {
    opacity: 1;
    transform: scale(1.05);
  }
  60% {
    opacity: 1;
    transform: scale(0.99);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes #{$namespace}-out {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.8);
  }
}
